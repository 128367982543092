export default {
    path: "/titleCompany/dashboard",
    component: () => import( /* webpackChunkName: "dashboardMain" */ "@/modules/dashboard/DashboardMain.vue"),
    meta: {
        requiresAuth: true,
        requiresOrder: true,
        label: "Dashboard",
        include: false,
        scrollLayout: false
    },
    children: [
        {
            path: "/titleCompany/dashboard/SelectFile",
            name: "titlecompany:fmfiles",
            component: () =>
                import(
                    /* webpackChunkName: "selectFile" */ "@/modules/fileMaintenance/SelectFile.vue"
                ),
            meta: {
                requiresAuth: true,
                requiresOrder: false,
                theme: "blue",
                label: "Search File",
                showAddFile: true
            }
        },
        {
            path: "/titleCompany/dashboard/AddFile",
            name: "titlecompany:fmaddfile",
            component: () =>
            import(
                /* webpackChunkName: "addfile" */ "@/modules/fileMaintenance/AddFile.vue"
            ),
            meta: {
                requiresAuth: true,
                requiresOrder: false,
                theme: "blue",
                label: "Create New File",
                showAddFile: false
            }
        }
    ],
};